import _round from "lodash/round";

import { commonConfig } from "@/utils/config";

import AppImage from "@/components/AppImage";

import { useMemo } from "react";

import useStyles from "./LogoImage.styles";

type CustomLogoImageProps = {
  variant?: keyof typeof variantToImgPathMap;
  alt?: string;
};

export type LogoImageProps = CustomLogoImageProps &
  Omit<
    React.ComponentPropsWithRef<typeof AppImage>,
    keyof CustomLogoImageProps | "src"
  >;

const variantToImgPathMap = {
  logo: "/images/logo.svg",
  lightLogo: "/images/light-logo.svg",
};

const LogoImage = (props: LogoImageProps) => {
  const {
    className,
    width: controlledWidth,
    height: controlledHeight,
    variant = "logo",
    alt = `Logo - ${commonConfig.DOCUMENT_TITLE}`,
    ...rest
  } = props;

  const { classes, cx } = useStyles({
    width: controlledWidth,
    height: controlledHeight,
  });

  const ratio = useMemo(() => {
    switch (variant) {
      case "logo": {
        return 100 / 28.5;
      }
      case "lightLogo": {
        return 100 / 28.5;
      }
    }
  }, [variant]);

  const width = useMemo(() => {
    switch (variant) {
      case "logo": {
        return 100;
      }
      case "lightLogo": {
        return 100;
      }
    }
  }, [variant, ratio]);

  const height = useMemo(() => {
    switch (variant) {
      case "logo": {
        return Math.round(100 / ratio);
      }
      case "lightLogo": {
        return Math.round(100 / ratio);
      }
    }
  }, [variant, ratio]);

  return (
    <AppImage
      {...rest}
      alt={alt}
      width={width}
      height={height}
      className={cx(classes.root, className)}
      src={variantToImgPathMap[variant]}
    />
  );
};

export default LogoImage;
