import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "mainAppToolbar",
})((theme) => {
  return {
    root: {
      width: "100%",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        minHeight: 40,
        height: 40,
      },
    },
  };
});

export default useStyles;
