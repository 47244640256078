import { alpha, darken, rgbToHex } from "@mui/material/styles";
import _get from "lodash/get";
import { makeStyles } from "tss-react/mui";
import type { AppButtonProps } from "./AppButton";

type MakeStylesParams = {
  color: AppButtonProps["color"];
  textColor: AppButtonProps["textColor"];
  disableHoverEffect: boolean;
};

const textColorToThemePalettePathMap = {
  primary: "primary.contrastText",
  secondary: "secondary.contrastText",
} as {
  [key: string]: string;
};

const colorToThemePalettePathMap = {
  primary: "primary.main",
  secondary: "secondary.main",
} as {
  [key: string]: string;
};

const useStyles = makeStyles<
  MakeStylesParams,
  | "containedEdgeStart"
  | "containedEdgeEnd"
  | "containedEdgeTop"
  | "containedEdgeBottom"
  | "containedEdgeX"
  | "containedEdgeY"
  | "containedEdgeXY"
  | "disabled"
>({
  name: "appButton",
  uniqId: "kta7lJ",
})((theme, params, classes) => {
  const color = (_get(
    theme.palette,
    colorToThemePalettePathMap[params.color!]
  ) ||
    _get(theme.palette, params.color!) ||
    (params.color ?? "white")) as string;

  const textColor =
    _get(theme.palette, textColorToThemePalettePathMap[params.color!]) ||
    _get(theme.palette, params.textColor!) ||
    (params.textColor ?? "white");

  const disableHoverEffect = !!params.disableHoverEffect;

  const containedSizeMediumPaddingX = "30px" as unknown as number;
  const containedSizeMediumPaddingY = "16.75px" as unknown as number;

  const textSizeMediumPaddingX = "16px" as unknown as number;
  const textSizeMediumPaddingY = "16.5px" as unknown as number;

  const outlinedSizeMediumPaddingX = "29px" as unknown as number;
  const outlinedSizeMediumPaddingY = "15.75px" as unknown as number;

  return {
    root: {
      fontFamily: theme.typography.button.fontFamily,
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: "normal",
      color,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: disableHoverEffect
          ? color
          : alpha(color, theme.palette.action.hoverOpacity),
      },
      [`&.${classes.disabled}`]: {
        color: theme.palette.grey[400],
      },
    },
    borderRadiusRounded: {
      borderRadius: theme.shape.borderRadius,
    },
    borderRadiusRoundedCircle: {
      borderRadius: "50px",
    },

    text: {},
    textSizeMedium: {
      padding: theme.spacing(textSizeMediumPaddingY, textSizeMediumPaddingX),
    },
    textEdgeStart: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeEnd: {
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeTop: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeBottom: {
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeX: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeY: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeXY: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },

    outlined: {
      border: `1px solid ${color}`,
      color: params.textColor === "default" ? color : textColor,
      "&:hover": !disableHoverEffect
        ? {
            backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
          }
        : undefined,
    },
    outlinedSizeMedium: {
      padding: theme.spacing(
        outlinedSizeMediumPaddingY,
        outlinedSizeMediumPaddingX
      ),
    },
    outlinedEdgeStart: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeEnd: {
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeTop: {
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeBottom: {
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeX: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeY: {
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeXY: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },

    contained: {
      backgroundColor: color,
      color: textColor,
      "&:hover": {
        backgroundColor: disableHoverEffect
          ? color
          : darken(color, theme.palette.contrastThreshold / 10),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
      },
    },
    containedSizeMedium: {
      padding: theme.spacing(
        containedSizeMediumPaddingY,
        containedSizeMediumPaddingX
      ),
    },
    containedEdgeStart: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeEnd: {
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeTop: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeBottom: {
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeX: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeY: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeXY: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    disabled: {},
  };
});

export default useStyles;
