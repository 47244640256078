import { commonHelpers } from "@/utils/helpers";
import { contactConstants } from "@/utils/constants";

import { useState } from "react";

import { ButtonBase, ClickAwayListener, Typography } from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import useStyles from "./ContactFloating.styles";

const ContactFab = () => {
  const { classes, cx } = useStyles();

  const [openContact, setOpenContact] = useState(false);

  const handleContactListOpen = (event: React.MouseEvent<any>) => {
    !openContact && event.preventDefault();
    setOpenContact(true);
  };

  const handleContactListClose = () => {
    setOpenContact(false);
  };

  return (
    <div className={cx(classes.root, !openContact && classes.collapsed)}>
      <ClickAwayListener
        onClickAway={
          commonHelpers.isMobile() ? handleContactListClose : () => {}
        }
      >
        <div
          className={classes.contactList}
          onMouseLeave={
            !commonHelpers.isMobile() ? handleContactListClose : () => {}
          }
          onMouseOver={
            !commonHelpers.isMobile() ? handleContactListOpen : () => {}
          }
          onClick={commonHelpers.isMobile() ? handleContactListOpen : () => {}}
        >
          <ButtonBase
            component={"a"}
            href={`tel:${contactConstants.HOTLINE_NUMBER}`}
            className={classes.contactItem}
          >
            <div className={classes.contactItemIcon}>
              <PhoneIcon />
            </div>
            <Typography className={classes.contactItemText}>
              {contactConstants.HOTLINE}
            </Typography>
          </ButtonBase>
          <ButtonBase
            component={"a"}
            href={`mailto:${contactConstants.HOTLINE}`}
            className={classes.contactItem}
          >
            <div className={classes.contactItemIcon}>
              <EmailIcon />
            </div>
            <Typography className={classes.contactItemText}>
              {contactConstants.EMAIL}
            </Typography>
          </ButtonBase>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ContactFab;
