import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "selected">({
  name: "mainHeader",
  uniqId: "fAxhpl",
})((theme, _, classes) => {
  return {
    contactBar: {
      color: theme.palette.common.white,
    },
    mainContactToolbar: {
      gap: theme.spacing(2),
    },
    mainContactToolbarDivider: {
      height: 16,
      borderColor: theme.palette.common.white,
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
      overflow: "hidden",
    },
    appBarScrollDown: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    mainAppToolbar: {
      gap: theme.spacing(40 / 8),
    },
    logo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      gap: theme.spacing(0.5),
      "&:before": {
        content: '""',
        position: "absolute",
        top: "calc(40px/2)",
        left: 2,
        transform: "translateY(-50%)",
        width: 20,
        height: 2,
        backgroundColor: theme.palette.primary.main,
      },
      "&:after": {
        content: '""',
        position: "absolute",
        top: "calc(40px/2)",
        right: 2,
        transform: "translateY(-50%)",
        width: 20,
        height: 2,
        backgroundColor: theme.palette.primary.main,
      },
    },
    logoImage: {
      height: 40,
      width: "auto",
      [theme.breakpoints.down("md")]: {
        height: 28,
      },
    },
    logoTitle: {
      fontWeight: 500,
      color: theme.palette.primary.main,
      textTransform: "capitalize",
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    menuList: {
      flex: 1,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      flexWrap: "nowrap",
      gap: theme.spacing(40 / 8),
      height: "100%",
    },
    menuItem: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    menuItemText: {
      position: "relative",
      padding: theme.spacing(1.25, 0),
      textTransform: "capitalize",
      fontWeight: 400,
      transition: theme.transitions.create(["all"]),
      color: theme.palette.text.primary,
      cursor: "pointer",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 2,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        transition: theme.transitions.create(
          ["width", "color", "font-weight"],
          {
            duration: theme.transitions.duration.enteringScreen,
          }
        ),
      },
      "&:hover": {
        fontWeight: 500,
        color: theme.palette.primary.main,
        "&:before": {
          width: "100%",
        },
      },
      [`.${classes.selected} &`]: {
        fontWeight: 500,
        color: theme.palette.primary.main,
        "&:before": {
          width: "100%",
        },
      },
    },
    selected: {},
  };
});

export default useStyles;
