import { forwardRef, useCallback, useEffect, useState } from "react";

import { commonConfig } from "@/utils/config";

import Image from "next/image";

import useStyles from "./AppImage.styles";

import type { ImageProps } from "next/image";

export type AppImageProps = {
  objectFit?: "fill" | "contain" | "cover" | "scale-down" | "none" | string;
  objectPosition?: string;
  defaultImgSrc?: ImageProps["src"];
} & ImageProps;

const AppImage = (props: AppImageProps, ref: React.ForwardedRef<any>) => {
  const {
    className,
    src,
    width,
    height,
    defaultImgSrc = "/images/logo.svg",
    alt,
    objectFit,
    objectPosition,
    onError,
    ...rest
  } = props;

  const [imgSrc, setImgSrc] = useState(src);
  const [isErrorImg, setIsErrorImg] = useState(false);

  const { classes, cx } = useStyles({
    objectFit,
    objectPosition,
    width,
    height,
  });

  const handleError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      setIsErrorImg(true);
      setImgSrc(defaultImgSrc);
      onError && onError(event);
    },
    []
  );

  useEffect(() => {
    setImgSrc(src);
    setIsErrorImg(false);
  }, [src]);

  return (
    <Image
      ref={ref}
      {...rest}
      width={width}
      height={height}
      className={cx(
        classes.root,
        classes.img,
        isErrorImg && classes.errorImg,
        className
      )}
      src={imgSrc || "/"}
      alt={alt || commonConfig.DOCUMENT_TITLE}
      onError={handleError}
    />
  );
};

const AppImageWithRef = forwardRef(AppImage);

export default AppImageWithRef;
