import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import ContactFloating from "@/components/ContactFloating";

import useStyles from "./Main.styles";

type MainProps = {
  children: React.ReactNode;
  disableMainHeaderAppToolbar?: boolean;
  disableMainHeaderContactToolbar?: boolean;
};

const Main = (props: MainProps) => {
  const {
    disableMainHeaderAppToolbar,
    disableMainHeaderContactToolbar,
    children,
  } = props;

  const { classes } = useStyles();

  return (
    <>
      <div className={classes.root}>
        <MainHeader
          disableAppToolbar={disableMainHeaderAppToolbar}
          disableContactToolbar={disableMainHeaderContactToolbar}
        />
        <main className={classes.main}>{children}</main>
        <MainFooter />
      </div>
      <ContactFloating />
    </>
  );
};

export default Main;
