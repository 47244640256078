import { ToastContainer } from "react-toastify";
import { appWithTranslation } from "next-i18next";
import * as locales from "@mui/material/locale";
import { Roboto } from "next/font/google";
import { DefaultSeo } from "next-seo";
import aos from "aos";

import nextI18NextConfig from "@@/next-i18next.config";
import { createEmotionCacheApp, createEmotionCacheMui } from "@/libs";
import defaultTheme from "@/assets/theme";
import { commonConfig } from "@/utils/config";
import { storageService } from "@/services";

import { TssCacheProvider } from "tss-react";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

import LoadingScreenOverlay from "@/components/LoadingScreenOverlay";
import AlertDialog from "@/components/AlertDialog";
import RouterLoadingLinearProgress from "@/components/RouterLoadingLinearProgress";

import { useTranslation } from "next-i18next";

import { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";

import "@/assets/scss/app.scss";
import "moment/locale/vi";

import type { NextPage } from "next";
import type { UseTranslationResponse } from "react-i18next";
import type { AppProps } from "next/app";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (
    page: ReactElement,
    pageProps: P,
    appProps: {
      translation: UseTranslationResponse<"common">;
    }
  ) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
  emotionCacheMui?: EmotionCache;
  emotionCacheApp?: EmotionCache;
}

type SupportedLocales = keyof typeof locales;

const fontApp = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-app",
});

const clientSideEmotionCacheMui = createEmotionCacheMui();
const clientSideEmotionCacheApp = createEmotionCacheApp();

const theme = createTheme(defaultTheme);

const InitializeMyAppEffect = () => {
  const theme = useTheme();

  const initAos = () => {
    aos.init({
      offset: 0,
      // Global settings:
      // disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      //startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
      //disableMutationObserver: true, // disables automatic mutations' detections (advanced)
      //debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      //throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      //offset: 120, // offset (in px) from the original trigger point
      // delay: 200, // values from 0 to 3000, with step 50ms
      duration: theme.transitions.duration.aos, // values from 0 to 3000, with step 50ms
      //easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      //anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  };

  useEffect(() => {
    initAos();
  }, []);

  return null;
};

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const {
    Component,
    emotionCacheMui = clientSideEmotionCacheMui,
    emotionCacheApp = clientSideEmotionCacheApp,
    pageProps,
  } = props;

  const locale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      ""
    ) as SupportedLocales) ||
    nextI18NextConfig.i18n.defaultLocale.replace("-", "");

  const headerLocale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      "_"
    ) as string) || nextI18NextConfig.i18n.defaultLocale.replace("-", "_");

  const getLayout = Component.getLayout ?? ((page) => page);
  const translation = useTranslation();

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [theme, locale]
  );

  const curVersion = storageService.getLocalItem("version");

  if (curVersion !== commonConfig.APP_VERSION) {
    storageService.clearLocal();
    storageService.saveLocalItem("version", commonConfig.APP_VERSION);
  }

  useEffect(() => {
    console.log(commonConfig.APP_VERSION);
  }, []);

  return (
    <>
      <DefaultSeo
        defaultTitle={commonConfig.DOCUMENT_TITLE}
        titleTemplate={`%s | ${commonConfig.DOCUMENT_TITLE}`}
        openGraph={{
          type: "website",
          locale: headerLocale,
          siteName: commonConfig.DOCUMENT_TITLE,
        }}
      />
      <style jsx global>{`
        html {
          font-family: ${fontApp.style.fontFamily};
        }
      `}</style>
      <div className={fontApp.variable}>
        <CacheProvider value={emotionCacheMui}>
          <TssCacheProvider value={emotionCacheApp}>
            <ThemeProvider theme={themeWithLocale}>
              <RouterLoadingLinearProgress />
              <CssBaseline />
              <InitializeMyAppEffect />
              {getLayout(<Component {...pageProps} />, pageProps, {
                translation,
              })}
              <LoadingScreenOverlay />
              <AlertDialog />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
              />
            </ThemeProvider>
          </TssCacheProvider>
        </CacheProvider>
      </div>
    </>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
