import { forwardRef } from "react";

import NextLink from "next/link";

import { Link } from "@mui/material";

import type { LinkProps } from "@mui/material";
import type { LinkProps as NextLinkProps } from "next/link";

export type AppLinkProps<
  D extends React.ElementType = "a",
  P = {}
> = NextLinkProps & Omit<LinkProps<D, P>, keyof NextLinkProps>;

const AppLink = <D extends React.ElementType = "a", P = {}>(
  props: AppLinkProps<D, P>,
  ref: React.ForwardedRef<any>
) => {
  const { href = "", ...rest } = props;

  return (
    <Link
      ref={ref}
      underline="none"
      color="initial"
      component={NextLink}
      href={href as any}
      {...rest}
    />
  );
};

const AppLinkWithRef = forwardRef(AppLink);

export default AppLinkWithRef;
