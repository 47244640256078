import { forwardRef } from "react";

import { commonHelpers } from "@/utils/helpers";

import { ButtonBase } from "@mui/material";

import type { ButtonBaseProps } from "@mui/material";

import useStyles from "./AppButton.styles";

type ButtonColor = "primary" | "secondary" | "inherit";
type CheckboxEdge = "start" | "end" | "top" | "bottom" | "x" | "y" | "xy";
type ButtonTextColor = "default";

type ButtonCustomProps<
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
> = {
  borderRadius?: "rounded" | "roundedCircle";
  edge?: CheckboxEdge | CheckboxEdge[];
  variant?: "text" | "contained" | "outlined";
  size?: "medium";
  color?: C;
  textColor?: T;
  disableHoverEffect?: boolean;
};

export type AppButtonProps<
  D extends React.ElementType = "button",
  P = {},
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
> = ButtonCustomProps<C, T> &
  Omit<ButtonBaseProps<D, P>, "size" | keyof ButtonCustomProps<C, T>> & {
    component?: D;
  };

const AppButton = <
  D extends React.ElementType = "button",
  P = {},
  C extends string = ButtonColor,
  T extends string = ButtonTextColor
>(
  props: AppButtonProps<D, P, C, T>,
  ref: React.ForwardedRef<any>
) => {
  const {
    borderRadius,
    edge,
    classes: muiClasses,
    className,
    variant = "text",
    color = "primary",
    textColor = "default",
    size = "medium",
    disableHoverEffect = false,
    ...rest
  } = props;

  const { classes, cx } = useStyles({
    color: color as any,
    textColor: textColor as any,
    disableHoverEffect,
  });

  const edges = Array.isArray(edge)
    ? edge
    : !commonHelpers.isEmpty(edge)
    ? [edge!]
    : [];

  const appButtonClasses = cx(classes.root, {
    [classes.borderRadiusRoundedCircle]: borderRadius === "roundedCircle",
    [classes.borderRadiusRounded]: borderRadius === "rounded",

    [classes.contained]: variant === "contained",
    [`${classes.containedSizeMedium} ${commonHelpers.generateClassName(
      "button",
      "containedSizeMedium"
    )}`]: variant === "contained" && size === "medium",
    [`${classes.containedEdgeStart} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeStart"
    )}`]: edges.includes("start") && variant === "contained",
    [`${classes.containedEdgeEnd} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeEnd"
    )}`]: edges.includes("end") && variant === "contained",
    [`${classes.containedEdgeTop} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeTop"
    )}`]: edges.includes("top") && variant === "contained",
    [`${classes.containedEdgeBottom} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeBottom"
    )}`]: edges.includes("bottom") && variant === "contained",
    [`${classes.containedEdgeX} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeX"
    )}`]: edges.includes("x") && variant === "contained",
    [`${classes.containedEdgeY} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeY"
    )}`]: edges.includes("y") && variant === "contained",
    [`${classes.containedEdgeXY} ${commonHelpers.generateClassName(
      "button",
      "containedEdgeXY"
    )}`]: edges.includes("xy") && variant === "contained",

    [classes.outlined]: variant === "outlined",
    [`${classes.outlinedSizeMedium} ${commonHelpers.generateClassName(
      "button",
      "outlinedSizeMedium"
    )}`]: variant === "outlined" && size === "medium",
    [`${classes.outlinedEdgeStart} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeStart"
    )}`]: edges.includes("start") && variant === "outlined",
    [`${classes.outlinedEdgeEnd} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeEnd"
    )}`]: edges.includes("end") && variant === "outlined",
    [`${classes.outlinedEdgeTop} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeTop"
    )}`]: edges.includes("top") && variant === "outlined",
    [`${classes.outlinedEdgeBottom} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeBottom"
    )}`]: edges.includes("bottom") && variant === "outlined",
    [`${classes.outlinedEdgeX} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeX"
    )}`]: edges.includes("x") && variant === "outlined",
    [`${classes.outlinedEdgeY} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeY"
    )}`]: edges.includes("y") && variant === "outlined",
    [`${classes.outlinedEdgeXY} ${commonHelpers.generateClassName(
      "button",
      "outlinedEdgeXY"
    )}`]: edges.includes("xy") && variant === "outlined",

    [classes.text]: variant === "text",
    [`${classes.textSizeMedium} ${commonHelpers.generateClassName(
      "button",
      "textSizeMedium"
    )}`]: variant === "text" && size === "medium",
    [`${classes.textEdgeStart} ${commonHelpers.generateClassName(
      "button",
      "textEdgeStart"
    )}`]: edges.includes("start") && variant === "text",
    [`${classes.textEdgeEnd} ${commonHelpers.generateClassName(
      "button",
      "textEdgeEnd"
    )}`]: edges.includes("end") && variant === "text",
    [`${classes.textEdgeTop} ${commonHelpers.generateClassName(
      "button",
      "textEdgeTop"
    )}`]: edges.includes("top") && variant === "text",
    [`${classes.textEdgeBottom} ${commonHelpers.generateClassName(
      "button",
      "textEdgeBottom"
    )}`]: edges.includes("bottom") && variant === "text",
    [`${classes.textEdgeX} ${commonHelpers.generateClassName(
      "button",
      "textEdgeX"
    )}`]: edges.includes("x") && variant === "text",
    [`${classes.textEdgeY} ${commonHelpers.generateClassName(
      "button",
      "textEdgeY"
    )}`]: edges.includes("y") && variant === "text",
    [`${classes.textEdgeXY} ${commonHelpers.generateClassName(
      "button",
      "textEdgeXY"
    )}`]: edges.includes("xy") && variant === "text",
  });

  return (
    <ButtonBase
      ref={ref}
      classes={{
        ...muiClasses,
        disabled: cx(classes.disabled, muiClasses?.disabled),
        root: cx(appButtonClasses, muiClasses?.root, className),
      }}
      {...rest}
    />
  );
};

const AppButtonWithRef = forwardRef(AppButton);

export default AppButtonWithRef;
