import dynamic from "next/dynamic";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import LayoutMain from "@/layouts/Main";
import LayoutCoreProvider from "@/layouts/CoreProvider";
import LazyLoading from "@/components/LazyLoading";

import type { NextPageWithLayout } from "@/pages/_app";
import type { GetStaticProps } from "next";

const ViewHome = dynamic(() => import("@/views/Home"), {
  suspense: true,
  ssr: false,
  loading: () => <LazyLoading />,
});

const Home: NextPageWithLayout = () => {
  return <ViewHome />;
};

Home.getLayout = (page) => {
  return (
    <LayoutCoreProvider>
      <LayoutMain>{page}</LayoutMain>
    </LayoutCoreProvider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  };
};

export default Home;
