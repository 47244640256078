declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { Paper } from "@mui/material";

import useStyles from "./AppPaper.styles";

import type { PaperProps } from "@mui/material";

type CustomPaper = {
  boxShadowVariant?: "menuPopper";
};

export type AppPaperProps<
  D extends React.ElementType = "div",
  P = {}
> = CustomPaper &
  Omit<PaperProps<D, P>, keyof CustomPaper> & {
    component?: D;
  };

const AppPaper = <D extends React.ElementType = "div", P = {}>(
  props: AppPaperProps<D, P>,
  ref: React.ForwardedRef<any>
) => {
  const { className, classes: muiClasses, boxShadowVariant, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Paper
      ref={ref}
      classes={{
        ...muiClasses,
        root: cx(
          classes.root,
          {
            [classes.boxShadowMenuPopper]: boxShadowVariant === "menuPopper",
          },
          muiClasses?.root,
          className
        ),
      }}
      elevation={0}
      {...rest}
    />
  );
};

const AppPaperWithRef = forwardRef(AppPaper);

export default AppPaperWithRef;
