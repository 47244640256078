import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "selected">({
  name: "mainHeader",
  uniqId: "GMrJOA",
})((theme, _, classes) => {
  return {
    mainAppToolbar: {
      position: "sticky",
      top: 0,
      backgroundColor: "inherit",
      zIndex: 1,
    },
    menuList: {
      width: 340,
      maxWidth: "100vw",
    },
    menuListItem: {
      minHeight: 50,
      fontWeight: 400,
      [`&.${classes.selected}`]: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },
    selected: {},
  };
});

export default useStyles;
