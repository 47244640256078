export const menus = [
  {
    href: "/gallery",
    title: "Gallery",
    activePathRegex: new RegExp("^/gallery"),
  },
  {
    href: "/exhibition-services",
    title: "Exhibition services",
    activePathRegex: new RegExp("^/exhibition-services"),
  },
  {
    title: "Our company",
    activePathRegex: new RegExp("^/contact-us|^/about-us"),
    menus: [
      {
        href: "/news",
        title: "News",
        activePathRegex: new RegExp("^/news"),
      },
      {
        href: "/contact-us",
        title: "Contact us",
        activePathRegex: new RegExp("^/contact-us"),
      },
      {
        href: "/about-us",
        title: "About us",
        activePathRegex: new RegExp("^/about-us"),
      },
    ],
  },
];
