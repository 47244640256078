import _keyBy from "lodash/keyBy";

import { contactConstants } from "@/utils/constants";

import { Box, Grid, Typography } from "@mui/material";
import AppContainer from "@/components/AppContainer";
import AppLink from "@/components/AppLink";
import LogoImage from "@/components/LogoImage";

import useStyles from "./MainFooter.styles";

const MainFooter = () => {
  const { classes } = useStyles();

  const curYear = new Date().getFullYear();

  return (
    <footer className={classes.root}>
      <AppContainer>
        <div className={classes.main}>
          <Grid container spacing={{ xs: 3, md: 12 }}>
            <Grid item xs={12} md="auto">
              <AppLink href="/" sx={{ display: "flex" }}>
                <LogoImage className={classes.logoImage} variant="lightLogo" />
              </AppLink>
            </Grid>
            <Grid item xs={12} md>
              <Grid
                container
                spacing={3}
                justifyContent={{ md: "space-around" }}
              >
                <Grid item xs={12} md={4}>
                  <Typography className={classes.title} variant="h6">
                    Hotline
                  </Typography>
                  <Typography className={classes.description}>
                    {contactConstants.HOTLINE}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={classes.title} variant="h6">
                    Email
                  </Typography>
                  <Typography className={classes.description}>
                    {contactConstants.EMAIL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={classes.title} variant="h6">
                    Address
                  </Typography>
                  <Typography className={classes.description}>
                    {contactConstants.ADDRESS}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.title} variant="h6">
                    About us
                  </Typography>
                  <Box display="flex">
                    <AppLink
                      href="/terms-and-conditions"
                      color="common.white"
                      underline="hover"
                      className={classes.description}
                    >
                      Terms and conditions
                    </AppLink>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.allRightsReserved}>
          © {curYear}. all rights reserved.
        </Typography>
      </AppContainer>
    </footer>
  );
};

export default MainFooter;
