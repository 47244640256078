import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "collapsed">({
  name: "contactFloating",
  uniqId: "y2xiEi",
})((theme, _, classes) => {
  return {
    root: {
      position: "fixed",
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
      zIndex: theme.zIndex.fab,
      width: 240,
      transition: theme.transitions.create(["width"], {
        duration: 1000,
      }),
      [theme.breakpoints.down("sm")]: {
        top: "initial",
        bottom: theme.spacing(3),
        transform: "initial",
      },
      [`&.${classes.collapsed}`]: {
        width: `calc(24px + ${theme.spacing(1.75 * 2)} + 2px)`,
      },
    },
    contactList: {
      display: "flex",
      flexDirection: "column",
      transform: "translateX(0%)",
      transition: theme.transitions.create(["transform"], {
        duration: 1000,
      }),
      width: 240,
      [`.${classes.collapsed} &`]: {
        whiteSpace: "nowrap",
        // transform: `translateX(calc(240px - 24px - ${theme.spacing(
        //   1.75 * 2
        // )} - 2px))`,
      },
    },
    contactItem: {
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.palette.primary.main}`,
      marginTop: -1,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      "&:first-of-type": {
        borderTopLeftRadius: theme.shape.borderRadius,
      },
      "&:last-of-type": {
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
    },
    contactItemIcon: {
      padding: theme.spacing(1.75),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
    },
    contactItemText: {
      padding: theme.spacing(1.75),
      width: `calc(240px - 24px - ${theme.spacing(1.75 * 2)} - 2px)`,
      color: theme.palette.text.primary,
    },
    collapsed: {},
  };
});

export default useStyles;
