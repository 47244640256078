import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "main",
})((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
    },
    main: {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  };
});

export default useStyles;
