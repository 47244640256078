import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import generalStyles from "./general.styles";
import overridesStyles from "./overrides.styles";
import customStyles from "./custom.styles";

const fontFamily = "var(--font-app), sans-serif";

const typography: TypographyOptions = {
  fontFamily: fontFamily,
  fontWeightBlack: 900,
  fontWeightExtraBold: 800,
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  h1: {
    fontFamily: fontFamily,
  },
  h2: {
    fontFamily: fontFamily,
  },
  h3: {
    fontFamily: fontFamily,
  },
  h4: {
    fontFamily: fontFamily,
  },
  h5: {
    fontFamily: fontFamily,
  },
  h6: {
    fontFamily: fontFamily,
    lineHeight: "normal",
  },
  body1: {
    fontFamily: fontFamily,
  },
  body2: {
    fontFamily: fontFamily,
  },
  button: {
    fontFamily: fontFamily,
  },
  caption: {
    fontFamily: fontFamily,
  },
  subtitle1: {
    fontFamily: fontFamily,
  },
  subtitle2: {
    fontFamily: fontFamily,
  },
  overline: {
    fontFamily: fontFamily,
  },
};

const colorToCodeMap = {
  red: "#9E2020",
  redLight: "#C36480",
  redLighter: "#D9A2B4",
  redLightest: "#F5E4E5",
};

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: colorToCodeMap.red,
    },
    common: {
      red: colorToCodeMap.red,
      redLight: colorToCodeMap.redLight,
      redLighter: colorToCodeMap.redLighter,
      redLightest: colorToCodeMap.redLightest,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParams: any) => `
				${generalStyles(themeParams)}
				${overridesStyles(themeParams)}
        ${customStyles(themeParams)}
			`,
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1350,
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: typography,
  zIndex: {
    backdrop: 1350,
  },
  transitions: {
    duration: {
      aos: 1000,
    },
  },
});

export default defaultTheme;
