declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { Toolbar, ToolbarProps } from "@mui/material";

import useStyles from "./MainContactToolbar.styles";

const MainAppToolbar = (props: ToolbarProps, ref: React.ForwardedRef<any>) => {
  const { classes: muiClasses, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <Toolbar
      classes={{
        ...muiClasses,
        root: cx(classes.root, muiClasses?.root && muiClasses.root),
      }}
      ref={ref}
      {...rest}
    />
  );
};

const MainAppToolbarWithRef = forwardRef(MainAppToolbar);

export default MainAppToolbarWithRef;
