import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "mainFooter",
})((theme) => {
  return {
    root: {
      color: theme.palette.common.white,
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: theme.palette.primary.main,
      },
    },
    main: {
      padding: theme.spacing(40 / 8, 0),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    logoImage: {
      height: 40,
      width: "auto",
      marginBottom: theme.spacing(0.5),
    },

    title: {
      marginBottom: theme.spacing(1),
      textAlign: "start",
      textTransform: "uppercase",
    },
    description: {
      textAlign: "start",
    },

    allRightsReserved: {
      position: "relative",
      padding: theme.spacing(2, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "uppercase",
    },
  };
});

export default useStyles;
