import { forwardRef } from "react";

import { IconButton } from "@mui/material";

import type { IconButtonProps } from "@mui/material";

type AppIconButtonProps<
  D extends React.ElementType = "button",
  P = {}
> = IconButtonProps<D, P> & {
  component?: D;
};

const AppIconButton = <D extends React.ElementType = "button", P = {}>(
  props: AppIconButtonProps<D, P>,
  ref: React.ForwardedRef<any>
) => {
  return <IconButton ref={ref} {...props} />;
};

const AppIconButtonWithRef = forwardRef(AppIconButton);

export default AppIconButtonWithRef;
