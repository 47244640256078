import { menus } from "./MainHeader.constants";
import { contactConstants } from "@/utils/constants";

import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  List,
  ListItem,
  Popper,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import AppContainer from "@/components/AppContainer";
import MainAppToolbar from "@/components/MainAppToolbar";
import AppLink from "@/components/AppLink";
import MainContactToolbar from "@/components/MainContactToolbar";
import AppIconButton from "@/components/AppIconButton";
import MenuDrawer from "./components/MenuDrawer";

import LogoImage from "@/components/LogoImage";

import MenuIcon from "@mui/icons-material/Menu";

import { useRouter } from "next/router";
import {
  cloneElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import useStyles from "./MainHeader.styles";
import AppPaper from "@/components/AppPaper";
import { useIsomorphicLayoutEffect } from "@/hooks";

type MainHeaderProps = {
  disableAppToolbar?: boolean;
  disableContactToolbar?: boolean;
};

const AppBarEffectScroll = (props: {
  window?: () => Window;
  children: React.ReactElement;
}) => {
  const { children, window } = props;

  const { classes, cx } = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    className: cx(classes.appBar, trigger && classes.appBarScrollDown),
    position: trigger ? "fixed" : "absolute",
    sx: {
      top: trigger ? 0 : 40,
    },
  });
};

const HeaderMenuItem = (props: { index: number }) => {
  const { index } = props;

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] =
    useState<HTMLDivElement>();

  const menuItemElRef = useRef<HTMLDivElement>(undefined!);
  const dropdownPaperElRef = useRef<HTMLDivElement>(undefined!);

  const menu = menus[index];

  const { classes, cx, theme } = useStyles();

  const router = useRouter();

  const handleDropdownClose = () => {
    setDropdownMenuOpen(false);
  };

  const handleDropdownOpen = () => {
    if (!menu.menus) return;
    setDropdownMenuOpen(true);
    setDropdownMenuAnchorEl(menuItemElRef.current);
  };

  useIsomorphicLayoutEffect(() => {
    if (!dropdownPaperElRef.current) return;
    const effectTimeout = setTimeout(() => {
      const dropdownPaperElRect =
        dropdownPaperElRef.current.getBoundingClientRect();
      dropdownPaperElRef.current.style.setProperty(
        "--pos-y",
        `${
          dropdownPaperElRect.y >= 0
            ? dropdownPaperElRect.y
            : dropdownPaperElRect.y * -1
        }px`
      );
    }, 150);
    return () => {
      clearTimeout(effectTimeout);
    };
  });

  return (
    <>
      <div
        ref={menuItemElRef}
        className={cx(
          classes.menuItem,
          (!!router.pathname.match(menu?.activePathRegex || "") ||
            !!dropdownMenuOpen) &&
            classes.selected
        )}
        onMouseLeave={handleDropdownClose}
      >
        <Typography
          variant="h6"
          component={menu.href ? AppLink : "p"}
          href={menu.href}
          className={classes.menuItemText}
          noWrap
          onMouseOver={handleDropdownOpen}
        >
          {menu.title}
        </Typography>
        {(menu.menus ?? []).length > 0 && (
          <Popper
            open={!!dropdownMenuOpen}
            anchorEl={dropdownMenuAnchorEl}
            placement="bottom-end"
            transition
            keepMounted
            style={{
              zIndex: theme.zIndex.tooltip,
            }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                timeout={theme.transitions.duration.shortest}
                style={{ transformOrigin: "100% 0 0" }}
              >
                <div>
                  <AppPaper
                    ref={dropdownPaperElRef}
                    boxShadowVariant="menuPopper"
                    sx={{
                      "--pos-y": "0px",
                      maxHeight: `calc(100vh - var(--pos-y) - 4px)`,
                      overflow: "auto",
                    }}
                  >
                    <List sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}>
                      {(menu.menus ?? []).map((childMenu, childMenuIndex) => (
                        <ListItem
                          key={childMenuIndex}
                          sx={{ paddingTop: 0, paddingBottom: 0 }}
                          className={cx(
                            !!router.pathname.match(
                              childMenu?.activePathRegex || ""
                            ) && classes.selected
                          )}
                        >
                          <Typography
                            component={childMenu.href ? AppLink : "div"}
                            href={childMenu.href}
                            className={classes.menuItemText}
                            noWrap
                            onClick={handleDropdownClose}
                          >
                            {childMenu.title}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </AppPaper>
                </div>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    </>
  );
};

const MenuSidebar = () => {
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  const handleMenuDrawerOpen = () => {
    setMenuDrawerOpen(true);
  };

  const handleMenuDrawerClose = () => {
    setMenuDrawerOpen(false);
  };

  return (
    <>
      <AppIconButton onClick={handleMenuDrawerOpen}>
        <MenuIcon />
      </AppIconButton>
      <MenuDrawer open={menuDrawerOpen} onClose={handleMenuDrawerClose} />
    </>
  );
};

const MainHeader = (props: MainHeaderProps) => {
  const { disableAppToolbar, disableContactToolbar } = props;

  const { classes, cx } = useStyles();

  const router = useRouter();

  return (
    <>
      <AppBar className={classes.contactBar} elevation={0}>
        <AppContainer>
          <MainContactToolbar
            className={classes.mainContactToolbar}
            disableGutters
          >
            <Typography>{contactConstants.HOTLINE}</Typography>
            <Divider
              className={classes.mainContactToolbarDivider}
              orientation="vertical"
            />
            <Typography>{contactConstants.EMAIL}</Typography>
          </MainContactToolbar>
        </AppContainer>
      </AppBar>
      <AppBarEffectScroll>
        <AppBar elevation={0}>
          <AppContainer>
            <MainAppToolbar className={classes.mainAppToolbar} disableGutters>
              <AppLink href="/">
                <div className={classes.logo}>
                  <LogoImage className={classes.logoImage} />
                  <Typography className={classes.logoTitle} variant="h4" noWrap>
                    Exhibition explore
                  </Typography>
                </div>
              </AppLink>

              <Hidden mdDown>
                <div className={classes.menuList}>
                  {menus.map((_, menuIndex) => (
                    <HeaderMenuItem key={menuIndex} index={menuIndex} />
                  ))}
                </div>
              </Hidden>

              <Hidden mdUp>
                <Box flex={1} />
                <MenuSidebar />
              </Hidden>
            </MainAppToolbar>
          </AppContainer>
        </AppBar>
      </AppBarEffectScroll>
      {!disableAppToolbar && <MainAppToolbar />}
      {!disableContactToolbar && <MainContactToolbar />}
    </>
  );
};

export default MainHeader;
