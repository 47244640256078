import { menus } from "../../MainHeader.constants";

import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AppIconButton from "@/components/AppIconButton";
import MainAppToolbar from "@/components/MainAppToolbar";
import AppLink from "@/components/AppLink";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import MenuIcon from "@mui/icons-material/Menu";

import { useState } from "react";
import { useRouter } from "next/router";

import useStyles from "./MenuDrawer.styles";

type MenuDrawerProps = {
  open?: boolean;
  onClose?: Function;
};

const MenuDrawer = (props: MenuDrawerProps) => {
  const { open, onClose } = props;

  const [menuIndexToOpenMap, setMenuIndexToOpenMap] = useState<{
    [key: number]: boolean;
  }>({});

  const { classes, cx } = useStyles();

  const router = useRouter();

  const handleMenuToggle = (menuIndex: number) => () => {
    setMenuIndexToOpenMap((prevMenuIndexToOpenMap) => ({
      ...prevMenuIndexToOpenMap,
      [menuIndex]: !prevMenuIndexToOpenMap[menuIndex],
    }));
  };

  return (
    <Drawer open={open} anchor="right" onClose={() => onClose && onClose()}>
      <MainAppToolbar className={classes.mainAppToolbar}>
        <Box flex={1} />
        <AppIconButton onClick={onClose}>
          <MenuIcon />
        </AppIconButton>
      </MainAppToolbar>
      <List className={classes.menuList}>
        {menus.map((menu, menuIndex) => (
          <>
            <ListItem
              key={menuIndex}
              className={cx(
                classes.menuListItem,
                !!router.pathname.match(menu?.activePathRegex || "") &&
                  classes.selected
              )}
              disablePadding
              onClick={
                !!menu.href
                  ? (onClose as any)
                  : (handleMenuToggle(menuIndex) as any)
              }
            >
              <ListItemButton
                component={menu.href ? AppLink : "button"}
                href={menu.href}
              >
                <ListItemText
                  primary={menu.title}
                  primaryTypographyProps={{
                    variant: "h6",
                    fontWeight: "inherit",
                  }}
                />
                {(menu.menus ?? []).length > 0 && (
                  <>
                    {!!menuIndexToOpenMap[menuIndex] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </>
                )}
              </ListItemButton>
            </ListItem>
            {(menu.menus ?? []).length > 0 && (
              <Collapse
                in={!!menuIndexToOpenMap[menuIndex]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  className={classes.menuList}
                  component="div"
                  disablePadding
                >
                  {menu.menus!.map((childMenu, childMenuIndex) => (
                    <ListItem
                      key={childMenuIndex}
                      className={cx(
                        classes.menuListItem,
                        !!router.pathname.match(
                          childMenu?.activePathRegex || ""
                        ) && classes.selected
                      )}
                      disablePadding
                    >
                      <ListItemButton
                        component={AppLink}
                        href={childMenu.href}
                        onClick={onClose}
                        sx={{ paddingLeft: 5 }}
                      >
                        <ListItemText
                          primary={childMenu.title}
                          primaryTypographyProps={{
                            variant: "h6",
                            fontWeight: "inherit",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
